import type { Arrayable } from '@vueuse/shared'
import type { Ref } from 'vue'

export function getPrices(...prices: Arrayable<number | { value: number } | { min: number; max: number } | undefined>[]): [number] | [number, number] {
  const result: number[] = []

  for (let i = 0; i < prices.length; i++) {
    const price = prices[i]

    if (isArray(price)) {
      result.push(...getPrices(...price))
    } else if (isObject(price) && 'min' in price && 'max' in price) {
      if (price.min === price.max)
        result.push(price.min)
      else
        result.push(price.min, price.max)
    } else if (typeof price === 'number') {
      result.push(price)
    }
  }

  return result.length === 1 ? [result[0]] : [Math.min(...result), Math.max(...result)]
}

export function usePriceFormatter(
  regular?: Ref<Arrayable<number | { value: number } | { min: number; max: number } | undefined>>,
  special?: Ref<Arrayable<number | { value: number } | { min: number; max: number } | undefined>>,
  currency?: Ref<string>,
) {
  const { n, locale, numberFormats } = useI18n()

  const currencyLocale = computed(() => {
    if (currency) {
      const currencyLocale = Object.keys(numberFormats.value).find((key) => {
        return numberFormats.value[key].currency.currency === currency.value
      })
      return currencyLocale ?? locale.value
    }
    return numberFormats.value[locale.value]
  })

  const regularPrice = computed(() => {
    if (regular?.value || regular.value === 0) {
      const prices = getPrices(regular.value).map(price => n(price / 100, 'currency', currencyLocale.value))
      return prices.length === 1 ? prices[0] : prices.join(' - ')
    }
    return ''
  })

  const specialPrice = computed(() => {
    if (special?.value || special.value === 0) {
      const prices = getPrices(special.value).map(price => n(price / 100, 'currency', currencyLocale.value))
      return prices.length === 1 ? prices[0] : prices.join(' - ')
    }
    return ''
  })

  return { regularPrice, specialPrice }
}

// TODO: Rewrite
export function formatPrice(price: Arrayable<number | { value: number } | { min: number; max: number }>, currency: string): string
export function formatPrice(prices: Arrayable<number | { value: number } | { min: number; max: number }>[], currency: string): string[]
export function formatPrice(...args: any[]) {
  const { n, locale, numberFormats } = useI18n()

  const currencyLocale = computed(() => {
    if (args[1]) {
      const currencyLocale = Object.keys(numberFormats.value).find((key) => {
        return numberFormats.value[key].currency.currency === args[1]
      })
      return currencyLocale ?? locale.value
    }
    return numberFormats.value[locale.value]
  })

  if (isArray(args[0])) {
    return args[0].map(price => n(price / 100, 'currency', currencyLocale.value))
  } else if (isObject(args[0]) && 'min' in args[0] && 'max' in args[0]) {
    if (args[0].min === args[0].max)
      return n(args[0].min / 100, 'currency', currencyLocale.value)
    else
      return [n(args[0].min / 100, 'currency', currencyLocale.value), n(args[0].max / 100, 'currency', currencyLocale.value)]
  } else {
    return n(args[0] / 100, 'currency', currencyLocale.value)
  }
}
