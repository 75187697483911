<script setup lang="ts">
import type { Arrayable } from '@vueuse/shared'
import { usePriceFormatter } from '~/composables/price'

const props = defineProps<{
  regular?: Arrayable<number | { value: number } | { min: number; max: number } | undefined>
  special?: Arrayable<number | { value: number } | { min: number; max: number } | undefined>
  currency?: string
}>()

const { regular, special, currency } = toRefs(props)

const { regularPrice, specialPrice } = usePriceFormatter(regular, special, currency)
</script>

<template>
  <div class="flex flex-gap-2">
    <slot name="regular" v-bind="{ props, specialPrice, regularPrice }">
      <span
        :class="{ hidden: (!regular && regular !== 0) || special }"
        class="regular-price text-sm font-medium md:text-base sm:text-base !text-primary-2" n=""
      >
        {{ regularPrice }}
      </span>
    </slot>

    <slot name="special" v-bind="{ props, specialPrice, regularPrice }">
      <span :class="{ hidden: !special }" class="special-price text-primary-2 font-medium" n="">
        {{ specialPrice }}
      </span>
    </slot>

    <slot name="old" v-bind="{ props, specialPrice, regularPrice }">
      <del :class="{ hidden: !special }" class="old-price hidden text-gray-400 font-light line-through" n="">
        {{ regularPrice }}
      </del>
    </slot>
  </div>
</template>
